import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";
import { undelineSM } from "../utils/cssGroups";

//  About page
const AboutPage = (props) => {
  // ---> Get all Signs <--- //
  const aboutBlurb = props.data.strapiAboutPage.AboutBlurb;
  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => (
            <p
              className="text-white w-full md:w-3/4 lg:w-4/6 lg:text-md mb-[1.3em] last:mb-[0]"
              {...props}
            />
          ),
          a: ({ node, ...props }) => (
            <a className={`${undelineSM} text-base lg:text-md `} {...props} />
          ),
        }}
      >
        {aboutBlurb.data.AboutBlurb}
      </ReactMarkdown>
    </Layout>
  );
};
export const query = graphql`
  query AboutQuery {
    strapiAboutPage {
      AboutBlurb {
        data {
          AboutBlurb
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default AboutPage;
